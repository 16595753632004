import tw from 'twin.macro';
const Container = tw.div`flex items-center justify-center p-3 bg-gradient-to-br from-indigo-200 to-purple-200 text-sm text-center`;
const Icon = tw.img`mr-1.5 w-5 h-5`;
const CTA = tw.a`text-purple-600 font-semibold hover:underline`;

const Promo = () => {
  return (
    <Container>
      <Icon alt="bonus" src="/bonus.png" />
      <span>推荐项目，最高 40% 奖励！</span>
      <CTA href="https://wj.qq.com/s2/9517779/bd18/" target="_blank" rel="noreferrer">
        推荐项目 →
      </CTA>
    </Container>
  );
};

export default Promo;
