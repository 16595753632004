import React from 'react';
import tw from 'twin.macro';
import { Link } from 'gatsby';

const Container = tw.footer`bg-gray-100 text-gray-600 text-sm`;
const NavContainer = tw.div`grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4 px-6 md:px-8 py-8 max-w-screen-xl mx-auto`;
const Logo = tw.img`w-[78px] col-span-2 md:col-span-4 lg:col-span-1`;
const Nav = tw.div`flex flex-col items-start`;
const NavTitle = tw.div`py-1.5 text-base font-semibold`;
const linkStyle = 'flex items-center py-0.5 font-light hover:(text-purple-600 underline)';
const NavLink = tw(Link)`${linkStyle}`;
const NavA = tw.a`${linkStyle}`;
const Icon = tw.img`mr-1 w-5 h-5`;
const LegalContainer = tw.div`flex flex-col items-center py-8 max-w-screen-xl mx-auto border-t text-gray-500`;
const Flex = tw.div`flex items-center`;
const IcpIcon = tw.img`w-4 h-4`;

const Footer = () => (
  <Container>
    <NavContainer>
      <Logo alt="无矩" src="/logo--b.svg" />
      <Nav>
        <NavTitle>解决方案</NavTitle>
        <NavLink to="/service/web-development/">通用 Web 开发</NavLink>
        <NavLink to="/service/system-integration/">系统集成</NavLink>
        <NavLink to="/service/internet-of-things/">物联网丨智能硬件</NavLink>
        <NavLink to="/service/application-development/">跨平台 App 开发</NavLink>
        <NavLink to="/consult/">
          <Icon alt="WeChat" src="/wechat.svg" />
          <span>在线咨询</span>
        </NavLink>
      </Nav>
      <Nav>
        <NavTitle>文档库</NavTitle>
        <NavLink to="/document/project-lifecycle/">项目生命周期</NavLink>
        <NavLink to="/document/tech-stack/">技术栈</NavLink>
      </Nav>
      <Nav>
        <NavTitle>了解我们</NavTitle>
        <NavLink to="/about/code-of-conduct/">宗旨</NavLink>
        <NavLink to="/about/join-us/">加入我们，共同成长</NavLink>
      </Nav>
      <Nav>
        <NavTitle>探索</NavTitle>
        <NavLink to="/blog/">博客</NavLink>
        <NavA href="https://isoftit.coding.net" target="_blank" rel="noopener noreferrer">
          <Icon alt="Coding.net" src="/coding.net.svg" />
          <span>跟踪项目进度</span>
        </NavA>
      </Nav>
    </NavContainer>
    <LegalContainer>
      <div>© {new Date().getFullYear()} 无矩（上海）技术咨询中心</div>
      <div>
        <a target="_blank" rel="noreferrer" href="https://beian.miit.gov.cn/">
          沪 ICP 备 2020036460 号 - 1
        </a>
      </div>
      <Flex>
        <a
          target="_blank"
          rel="noreferrer"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202013256"
        >
          <IcpIcon alt="备案" src="/beian.png" />
        </a>
        <span>&nbsp;沪公网安备 31011202013256 号</span>
      </Flex>
    </LegalContainer>
  </Container>
);

export default Footer;
