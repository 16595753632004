import tw, { GlobalStyles } from 'twin.macro';
import { StringParam, useQueryParam } from 'use-query-params';
import Footer from '../components/footer';
import Promo from '../components/promo';
import TopNav from '../components/top-nav';
import './landing.css';

const HiddenLogoContainer = tw.div`fixed hidden`;
const Container = tw.div`flex flex-col min-h-full`;
const Main = tw.main`flex-1`;

const Landing: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [channel] = useQueryParam('c', StringParam);

  return (
    <Container>
      <HiddenLogoContainer>
        <img src="/logo-bg.png" />
      </HiddenLogoContainer>
      <GlobalStyles />
      {!channel && <Promo />}
      <TopNav />
      <Main>{children}</Main>
      <Footer />
    </Container>
  );
};
export default Landing;
