import React, { useState } from 'react';
import tw from 'twin.macro';
import { Link } from 'gatsby';

const Container = tw.header`sticky top-0 bg-white text-gray-600 shadow-sm z-10`;
const InnerContainer = tw.div`flex items-center justify-between mx-auto px-6 md:px-8 h-12 md:h-16 max-w-screen-xl`;
const Logo = tw.img`w-[78px]`;
const NavContainer = tw.div`hidden md:block`;
const NavItem = tw(Link)`px-4 py-2 rounded hover:bg-purple-100 hover:text-purple-600`;

const MenuActioin = tw.div`md:hidden flex items-center pl-2 text-black text-sm font-semibold cursor-pointer`;
const MenuIcon = tw.img`p-2 w-10 h-10`;
const MenuContainer = tw.div`md:hidden absolute flex flex-col top-12 left-0 w-full px-8 py-8 bg-white z-10 shadow-lg`;
const MenuItem = tw(Link)`py-2 text-black text-center`;

const TopNav = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Container>
      <InnerContainer>
        <Link to="/">
          <Logo alt="无矩" src="/logo.svg" />
        </Link>
        <NavContainer>
          <NavItem to="/service/">解决方案</NavItem>
          <NavItem to="/document/">文档库</NavItem>
          <NavItem to="/about/">了解我们</NavItem>
          <NavItem to="/blog/">博客</NavItem>
          <NavItem to="/consult/">联系方式</NavItem>
        </NavContainer>
        {menuOpen ? (
          <MenuActioin onClick={() => setMenuOpen(false)}>
            收起
            <MenuIcon src="/close.svg" />
          </MenuActioin>
        ) : (
          <MenuActioin onClick={() => setMenuOpen(true)}>
            菜单
            <MenuIcon src="/menu.svg" />
          </MenuActioin>
        )}
        {menuOpen && (
          <MenuContainer>
            <MenuItem to="/service/">解决方案 ›</MenuItem>
            <MenuItem to="/document/">文档库 ›</MenuItem>
            <MenuItem to="/about/">了解我们 ›</MenuItem>
            <MenuItem to="/blog/">博客 ›</MenuItem>
            <MenuItem to="/consult/">联系方式 ›</MenuItem>
          </MenuContainer>
        )}
      </InnerContainer>
    </Container>
  );
};

export default TopNav;
