import React from 'react';
import { Helmet, HelmetProps } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO: React.FC<HelmetProps> = (props) => {
  const data = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          title
          titleTemplate
          description
          siteUrl
          keywords
          image
        }
      }
    }
  `);

  const { siteMetadata } = data.site;
  const {
    title,
    titleTemplate,
    description,
    siteUrl,
    meta = [],
    keywords = [],
    image,
  } = siteMetadata;
  const currentTitle = props.title || title;
  const currentTitleTemplate = props.titleTemplate || titleTemplate;
  const metaData = [
    {
      name: 'canonical',
      content: siteUrl,
    },
    {
      name: 'description',
      content: description,
    },
    {
      name: 'image',
      content: image,
    },
    {
      name: 'og:url',
      content: siteUrl,
    },
    {
      name: 'og:type',
      content: 'article',
    },
    {
      name: 'og:title',
      content: currentTitle,
    },
    {
      name: 'og:description',
      content: description,
    },
    {
      name: 'og:image',
      content: image,
    },
    {
      name: 'keywords',
      content: keywords.join(','),
    },
  ].concat(meta);

  const linkData = [
    {
      rel: 'shortcut icon',
      href: '/favicon.ico',
    },
  ];

  return (
    <Helmet
      htmlAttributes={{ lang: 'zh-cmn-Hans' }}
      title={currentTitle}
      titleTemplate={currentTitleTemplate}
      meta={metaData}
      link={linkData}
    />
  );
};

export default SEO;
